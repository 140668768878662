@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #000000 ;
    --color-bg-variant: #141414;
    --color-primary: #0CE964;
    --color-primary-variant: #089B43;
    --color-white: #F4F7F5;
    --color-light: #8E95A4;

    --transition: all 400ms ease;
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-ms: 90%;
}

html{
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body{
    font-family: 'Bitter', serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    background-image: url(../src/assets/dark-matter.png);
}

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight:500;
}

h1 {
    font-size: 2;
}

section {
    margin-top: 8rem;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);

}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);

}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);


}

.btn:hover {
    background: var(--color-white);
    color: var(var(--color-bg));
    border-color: transparent
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ======================== Media Queries (Medium devices) ==============*/
@media screen and (max-width: 1024px){
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem
    }
}

@media screen and (max-width: 600px){
    .container {
        width: var(--container-width-ms);
    }

    section > h2 {
        margin-top: 6rem
    }
}


/* Fade in parts*/
.fade-in-section {
    opacity: 0;
    transform: translateY(10vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1s ease-out;
    will-change: opacity, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }