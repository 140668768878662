header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}


.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}

.cta {
    margin-top: 2rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content:"";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}
.header__socials::before {
  content:"";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

.placeholder {
    background: linear-gradient(var(--color-primary), transparent 55%);
    position: absolute;
    width: 20rem;
    height: 28rem;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 4rem;
    overflow: hidden;
    border-radius: 12rem 12rem 0 0;
}

.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}
.Typist {
    color:rgb(235, 222, 212);
    text-align: center;
    font-size: 76px;
    margin-top: -30px;
  }

.Typist .Cursor--blinking {
    color:#0CE964;
    padding-left: 4px;
  }

.intro-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    font-family: 'Bitter', serif;
    font-size: 86px;
  }
.intro-name {
    font-family: 'Bitter', serif;
    color:#0CE964;
    font-size: 86px;
}


.intro-subtitle {
    font-family: 'Bitter', serif;
    margin-top: -215px;
    color: rgb(61, 66, 59);
    font-weight: 300;
    font-size: 48px;
  }
  
  .intro-desc {
    color:  rgb(61, 66, 59);
    font-size: 22px;
    max-width: 40%;
    text-align: justify;
    padding-bottom: 50px;
  }

@media screen and (max-width: 1024px){
    header {
        height: 68vh;

    }
    .intro-subtitle {
      margin: 90px;
      font-size: 38px;
    }
    .intro-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      font-family: 'Bitter', serif;
      font-size: 86px;
    }


}

@media screen and (max-width: 600px){
    header {
        height: 100vh;
    }
    .header__socials,
    .scroll__down {
        display: none;
    }
    .intro-title {
        font-size: 36px;
      }
      .intro-name {
        font-size: 36px;
      }
    
      .Typist {
        font-size: 36px;
      }
      .intro-title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        font-family: 'Bitter', serif;
        font-size: 86px;
      }
    
      .intro-subtitle {
        margin: 60px;
        font-size: 28px;
        text-align: center;
      }

}